body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  width: 100%;
  height: 80px;
  top: 0;
  border-bottom: solid 1px #dde3e7;
}

.footer {
  width: 100%;
}

.main {
  margin-top: 50px;
  min-height: calc(100vh - 210px);
}

.message {
  font-weight: bold;
  background-color: #eef3f6;
  padding: 8px;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 1280px) {
  #username {
    margin-right: 10px !important;
  }

  #logo {
    margin-left: 10px !important;
  }
}

@media (max-width: 305px) {
  #username {
    margin-left: 1px !important;
    margin-right: auto !important;
  }

  #logo {
    text-align: center;
  }
}

.datepicker-input {
  text-align: center;
  margin-top: 20px;
  width: 20ch;
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #0d6efd;
  font: inherit;
  color: #fff;
}

.date-input {
  /*width: 50%;*/
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2px;
}

.date-input input {
  flex-grow: 2;
  border: none;
}

.date-input button {
  color: #086ADB;
}

.export-icon img {
  width: 24px;
}

.table caption {
  caption-side: top;
  text-align: left;
  font-size: 1.1em;
  font-weight: 900;
}

.table tr {
  border-bottom: 1px solid #adadad;
}

.link {
  color: #015;
}

.link::before {
  content: "> ";
}

.link:hover {
  cursor: pointer;
  color: #2d90ec;
}

.linkimg img {
  transform: scale(0.25);
}

.linkimg:hover {
  cursor: pointer;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 270px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 60%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  text-align: center;
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 2px;
  right: 5px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #f00;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.btn-close {
  position: relative;
  float: right;
  margin: 0;
  border: 0;
  padding: 0;
  background: #0066ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
}

.btn-close .icon-cross {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 20px;
  height: 20px;
}

.btn-close .icon-cross:before,
.btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  height: 3px;
  background: #fff;
  border-radius: 3px;
}

.btn-close .icon-cross:before {
  transform: rotate(45deg);
}

.btn-close .icon-cross:after {
  transform: rotate(-45deg);
}

.btn-close .icon-cross span {
  display: block;
}

.btn-close:hover,
.btn-close:focus {
  transform: rotateZ(90deg);
  background: #0052cc;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}

.button {
  font-family: TheSans, sans-serif;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -.1px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0;
  border-color: #1781e3 !important;
  background-color: #1781e3 !important;
  caret-color: rgb(8, 106, 219) !important;
  border-radius: 5px;
  background: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 70ms cubic-bezier(.4, 0, .6, 1);
  transition: all 70ms cubic-bezier(.4, 0, .6, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: inline-block;
  outline: none;
  width: auto;
  min-width: 180px;
  margin: 8px;
}

.primary:hover {
  border-color: #0851da;
  background-color: #0851da;
}

.loader {
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
a:hover{
  text-decoration: none !important;

}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}